import { NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ButtonIconComponent } from '@mri-platform/ui-icon';
import { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';
import { ButtonProgressState } from './button-progress-state';

const defaultState: ButtonProgressState = Object.freeze({
  errored: false,
  succeeded: false,
  started: false
});

interface IconInfo {
  name: SvgIconName;
  clazz: string;
  testId: string;
}

function getIconInfo(state: ButtonProgressState): IconInfo {
  if (state.errored) {
    return {
      testId: 'progress-icon-error',
      name: 'critical-diamond',
      clazz: 'mri-icon-button__critical-icon'
    };
  } else if (state.succeeded) {
    return {
      testId: 'progress-icon-success',
      name: 'checkmark-circle',
      clazz: 'mri-icon-button__success-icon'
    };
  } else {
    // this is never actually shown so could be anything!
    return {
      testId: 'progress-icon-none',
      name: 'save',
      clazz: 'mri-icon--info'
    };
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[mriButtonProgress]',
  standalone: true,
  template: `
    <div #originalButton [hidden]="hideDefault">
      <ng-content></ng-content>
    </div>
    <div [ngStyle]="{ width: buttonWidth }" [hidden]="!hideDefault || isLoading">
      <mri-btn-icon
        [attr.data-testid]="iconInfo.testId"
        [icon]="iconInfo.name"
        class="{{ iconInfo.clazz }}"
      ></mri-btn-icon>
    </div>
    <div [ngStyle]="{ width: buttonWidth }" [hidden]="!isLoading">
      <span class="mri-button__label">Button</span>
      <progress class="mri-spinner mri-button__spinner mri-spinner--dark"></progress>
    </div>
  `,
  styleUrls: ['button-progress.component.scss'],
  imports: [ButtonIconComponent, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonProgressComponent implements AfterViewInit, OnDestroy {
  private _state = defaultState;

  get hideDefault() {
    return this._state.started || this._state.errored || this._state.succeeded;
  }
  iconInfo = getIconInfo(defaultState);
  buttonWidth = '';
  private timerId?: number;

  @HostBinding('class.mri-is-loading') get isLoading() {
    return this._state.started;
  }

  @ViewChild('originalButton') private originalButton: ElementRef | undefined;

  @Input('mriButtonProgress') set state(value: ButtonProgressState | undefined) {
    this.changeButtonState(value);
  }

  /**
   * Omits whenever the success or error button progress state has been reset after a delay.
   *
   * Typically you listen to this event to synchronise the value of `mriButtonProgress` object supplied as
   * an input for those scenarios where the button is toggled using an `ngIf` to show the button to the user
   * only when necessary
   */
  @Output() resetProgress = new EventEmitter<true>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.buttonWidth = `${this.originalButton?.nativeElement.offsetWidth}px`;
  }

  ngOnDestroy(): void {
    this.cancelTimer();
  }

  private cancelTimer() {
    if (!this.timerId) return;

    clearTimeout(this.timerId);
  }

  private changeButtonState(value: ButtonProgressState | undefined) {
    this.setState(value);
    this.cancelTimer();
    if (this._state.succeeded || this._state.errored) {
      this.timerId = window.setTimeout(() => {
        this.setState({ ...this._state, errored: false, succeeded: false });
        this.resetProgress.emit(true);
        this.changeDetectorRef.markForCheck();
      }, 2000);
    }
  }

  private setState(value: ButtonProgressState | undefined) {
    this._state = value ?? defaultState;
    this.iconInfo = getIconInfo(this._state);
  }
}
