import { BaseEntity } from '../base-entity';
import { OrganisationMembership } from './organisation-membership';
import { UserReportWorkspaceFilter } from './user-report-workspace-filter';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
/// </code-import>

export interface UserReportFilter {
  id: string;
  excludeUnassigned: boolean;
  excludeUnpublished: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  userId: string;
  excludedWorkspaces: UserReportWorkspaceFilter[];
  organisationMembership: OrganisationMembership;
}

/// <module-code> Place module level code between <module-code> tags
const typeFuncs = BaseEntity.makeStaticTypeFunctions<UserReportFilter>(EntityName.UserReportFilter);
/// </module-code>

export class UserReportFilter extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;
  /// </code>
  // Generated code. Do not place code below this line.
}
