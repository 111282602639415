import { DefaultEntity } from '@mri/breeze-entity';

export interface TypeFunctions<T extends BaseEntity> {
  propName: (name: keyof T) => keyof T;
  propNames: (...names: Array<keyof T>) => Array<keyof T>;
  qualifiedPropName: (name: keyof T) => string;
  qualifiedPropNames: (...names: Array<keyof T>) => string[];
}

export class BaseEntity extends DefaultEntity {
  static serverPlaceholderStringValue = 'REPLACED_ON_SERVER';

  static getPropPath(keys: string[]): string {
    return keys.reduce((acc, key) => `${acc}.${key}`);
  }

  static makeStaticTypeFunctions<T extends BaseEntity>(entityName: string): TypeFunctions<T> {
    const propName = (name: keyof T) => name;
    const qualifiedPropName = (name: keyof T) => `${entityName}.${name.toString()}`;
    return {
      propName,
      propNames: (...names: Array<keyof T>) => names.map(propName),
      qualifiedPropName,
      qualifiedPropNames: (...names: Array<keyof T>) => names.map(qualifiedPropName)
    };
  }
}
