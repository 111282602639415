import { BaseEntity } from '../base-entity';
import { Report } from './report';
import { UserPrincipal } from './user-principal';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
/// </code-import>

export interface ReportActivityRecord {
  id: string;
  activityDate: Date;
  activityType: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  reportId: string;
  tenantKey: string;
  userId: string;
  report: Report;
  user: UserPrincipal;
}

/// <module-code> Place module level code between <module-code> tags
export enum ReportActivityType {
  View = 'View',
  SaveDraft = 'SaveDraft',
  ImportDraft = 'ImportDraft',
  Publish = 'Publish'
}
const typeFuncs = BaseEntity.makeStaticTypeFunctions<ReportActivityRecord>(EntityName.ReportActivityRecord);
/// </module-code>

export class ReportActivityRecord extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;
  static authorization = AuthzContextsActionMap.crudFor(EntityName.ReportActivityRecord);
  /// </code>
  // Generated code. Do not place code below this line.
}
