import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonProgressState } from '@mri-platform/ui-buttons';
import { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';

@Component({
  selector: 'mri-shared-drawer-footer-toolbar',
  template: `
    @if (showDelete) {
      <div class="mri-toolbar__group">
        <button
          class="mri-button"
          [mriButtonProgress]="deleteProgressState"
          [disabled]="!canDelete"
          (click)="delete.emit()"
          (resetProgress)="deleteResetProgress.emit(true)"
        >
          <mri-btn-icon icon="trash-can"></mri-btn-icon>
          {{ deleteLabel }}
        </button>
      </div>
    }
    <div class="mri-toolbar__group">
      @if (showSave) {
        <button
          class="mri-button mri-button--primary"
          [mriButtonProgress]="saveProgressState"
          [disabled]="!canSave"
          (click)="save.next()"
          (resetProgress)="saveResetProgress.emit(true)"
        >
          <mri-btn-icon [icon]="saveIcon"></mri-btn-icon>
          {{ saveLabel }}
        </button>
      }
      <button class="mri-button" [disabled]="!canCancel" (click)="cancel.next()">
        <mri-btn-icon icon="x"></mri-btn-icon>
        {{ cancelLabel }}
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerFooterToolbarComponent {
  @Input() cancelLabel = 'Cancel';
  @Input() canCancel = false;
  @Input() canDelete = false;
  @Input() canSave = false;
  @Input() deleteLabel = 'Delete';
  @Input() deleteProgressState?: ButtonProgressState;
  @Input() showDelete = false;
  @Input() saveLabel = 'Save';
  @Input() showSave = true;
  @Input() saveIcon: SvgIconName = 'save';
  @Input() saveProgressState?: ButtonProgressState;
  @Output() delete = new EventEmitter();
  @Output() deleteResetProgress = new EventEmitter<true>();
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() saveResetProgress = new EventEmitter<true>();

  @HostBinding('class.mri-drawer__footer-toolbar')
  @HostBinding('class.mri-toolbar')
  readonly hostClass = true;
  @HostBinding('class.mri-toolbar--align-end') get alignEnd() {
    return !this.showDelete;
  }
}
