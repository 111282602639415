import { BaseEntity } from '../base-entity';
import { OrganisationWorkspace } from './organisation-workspace';
import { UserReportFilter } from './user-report-filter';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { Dataset } from './dataset';
import { Report } from './report';
import { ReportInfo } from './report-info';
/// </code-import>

export interface UserReportWorkspaceFilter {
  userId: string;
  workspaceId: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  userReportFilter: UserReportFilter;
  workspace: OrganisationWorkspace;
}

/// <module-code> Place module level code between <module-code> tags
type EntityType = ReportInfo | Report | Dataset;
export function excludeFilteredEntities<T extends EntityType>(
  entities: T[],
  workspaceFilters: UserReportWorkspaceFilter[]
): T[] {
  const filteredWorkspaceIds = workspaceFilters.map(x => x.workspaceId);
  return entities.filter(e => !filteredWorkspaceIds.includes(e.organisationWorkspaceId));
}

const typeFuncs = BaseEntity.makeStaticTypeFunctions<UserReportWorkspaceFilter>(EntityName.UserReportWorkspaceFilter);
/// </module-code>

export class UserReportWorkspaceFilter extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;
  /// </code>
  // Generated code. Do not place code below this line.
}
