import { BaseEntity } from '../base-entity';
import { TemplateWorkspaceIcon } from './template-workspace-icon';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
/// </code-import>

export interface OrganisationWorkspaceInfo {
  id: string;
  isDeleted: boolean;
  templateWorkspaceId: string;
  tenantKey: string;
  workspaceType: string;
  templateWorkspaceIcon: TemplateWorkspaceIcon;
}

/// <module-code> Place module level code between <module-code> tags
export enum WorkspaceType {
  Primary = 'Primary'
}

export type WorkspaceAvatarInfo = Pick<OrganisationWorkspaceInfo, 'workspaceType' | 'templateWorkspaceIcon'>;

export interface OrganisationWorkspaceInfo extends WorkspaceAvatarInfo {
  hasActiveDeployment: boolean;
}

const typeFuncs = BaseEntity.makeStaticTypeFunctions<OrganisationWorkspaceInfo>(EntityName.OrganisationWorkspaceInfo);
/// </module-code>

export class OrganisationWorkspaceInfo extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;

  get workspaceLabel() {
    return this.entityAspect.isNavigationPropertyLoaded(OrganisationWorkspaceInfo.propName('templateWorkspaceIcon'))
      ? this.templateWorkspaceIcon.label
      : '<<not loaded>>';
  }

  get workspaceFullLabel() {
    return `${this.workspaceLabel}${this.workspaceType === WorkspaceType.Primary ? '' : ` (${this.workspaceType})`}`;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
