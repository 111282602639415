import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { DataProperty, DataType, EntityType, MetadataStore, NavigationProperty, Validator } from 'breeze-client';
import { BaseEntity } from '../base-entity';
import { OrganisationWorkspaceInfo } from './organisation-workspace-info';

export interface Dataset {
  id: string;
  description?: string;
  embedUrl: string;
  name: string;
  webUrl: string;
  organisationWorkspaceId: string;
  organisationWorkspace: OrganisationWorkspaceInfo;
}

const typeFuncs = BaseEntity.makeStaticTypeFunctions<Dataset>(EntityName.Dataset);

export class Dataset extends BaseEntity {
  static propName = typeFuncs.propName;
  static authorization = AuthzContextsActionMap.crudFor(EntityName.Dataset);

  static register(metadataStore: MetadataStore) {
    metadataStore.registerEntityTypeCtor('Dataset', Dataset);

    const namespace = 'DataServicesGateway.Shared.Model';
    const et = new EntityType({
      shortName: EntityName.Dataset,
      namespace,
      defaultResourceName: 'Datasets',
      dataProperties: [
        new DataProperty({
          name: Dataset.propName(Dataset.propName('id')),
          dataType: DataType.Guid,
          isNullable: false,
          isPartOfKey: true,
          validators: [Validator.required(), Validator.guid()]
        }),
        new DataProperty({
          name: Dataset.propName(Dataset.propName('description')),
          dataType: DataType.String,
          isNullable: true,
          isPartOfKey: false
        }),
        new DataProperty({
          name: Dataset.propName(Dataset.propName('embedUrl')),
          dataType: DataType.String,
          isNullable: false,
          isPartOfKey: false,
          validators: [Validator.required(), Validator.url()]
        }),
        new DataProperty({
          name: Dataset.propName(Dataset.propName('name')),
          dataType: DataType.String,
          isNullable: false,
          isPartOfKey: false,
          validators: [Validator.required()]
        }),
        new DataProperty({
          name: Dataset.propName(Dataset.propName('webUrl')),
          dataType: DataType.String,
          isNullable: false,
          isPartOfKey: false,
          validators: [Validator.required(), Validator.url()]
        }),
        new DataProperty({
          name: Dataset.propName(Dataset.propName('organisationWorkspaceId')),
          dataType: DataType.Guid,
          isNullable: false,
          isPartOfKey: false,
          validators: [Validator.required(), Validator.guid()]
        })
      ],
      navigationProperties: [
        new NavigationProperty({
          name: Dataset.propName('organisationWorkspace'),
          entityTypeName: `${EntityName.OrganisationWorkspaceInfo}:#${namespace}`,
          associationName: `${EntityName.Dataset}_${EntityName.OrganisationWorkspaceInfo}_${EntityName.OrganisationWorkspace}`,
          foreignKeyNames: [Dataset.propName('organisationWorkspaceId')]
        })
      ]
    });

    metadataStore.addEntityType(et);
  }
}
