import { EntityQuery } from 'breeze-client';
import { Report } from '../../models';

const baseQuery = EntityQuery.from('Reports');
const reportAccess = baseQuery.expand(Report.propName('allowedPrincipals')).orderBy(Report.propName('name'));
const reportIconQuery = baseQuery.select([
  Report.propName('id'),
  Report.propPath('organisationWorkspace', 'templateWorkspace', 'icon')
]);

export const reportQueries = {
  selectAll: baseQuery,
  selectReportAccess: reportAccess,
  selectReportIcons: reportIconQuery
};
