import { IdentityPrincipal } from './identity-principal';
import { OrganisationMembership } from './organisation-membership';
import { UserRole } from './user-role';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName, domainNameWarning } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction, CrudAllAction } from '@mri-platform/resource-authz';
import { GraphLoadPurpose } from '@mri/breeze-entity';
import { DataProperty, DataType, MetadataStore } from 'breeze-client';
import { BaseEntity } from '../base-entity';
/// </code-import>

export interface UserPrincipal {
  email: string;
  firstName: string;
  identityProviderUserId: string;
  lastName: string;
  organisations: OrganisationMembership[];
  userRoles: UserRole[];
}

/// <module-code> Place module level code between <module-code> tags
export interface UserPrincipal {
  isAppUser: boolean;
}
export const userFullname = ({ firstName = '', lastName = '' }: UserPrincipal) => `${firstName} ${lastName}`.trim();

export type UserNames = Pick<UserPrincipal, 'name'>;

const typeFuncs = BaseEntity.makeStaticTypeFunctions<UserPrincipal>(EntityName.User);
/// </module-code>

export class UserPrincipal extends IdentityPrincipal {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;
  static propNames = typeFuncs.propNames;

  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.User),
    [CrudAllAction.Read]: AuthzContext.readAllFor(EntityName.User),
    [CrudAction.Delete]: [
      AuthzContext.deleteFor(EntityName.User),
      ...OrganisationMembership.authorization[CrudAction.Delete]
    ]
  };

  static register(metadataStore: MetadataStore) {
    metadataStore.setEntityTypeForResourceName('ExternalUsers', EntityName.User);

    // adding properties that our server does not provide a mapping for
    // (for example isAppUser is not persisted to the db)
    const et = metadataStore.getAsEntityType(EntityName.User, false);
    et.addProperty(
      new DataProperty({
        name: UserPrincipal.propName('isAppUser'),
        dataType: DataType.Boolean,
        isNullable: false,
        isPartOfKey: false,
        defaultValue: true,
        validators: []
      })
    );
    (et.getProperty(UserPrincipal.propName('identityProviderUserId'), true) as DataProperty).defaultValue =
      BaseEntity.serverPlaceholderStringValue;
  }

  verifyEmailDomain(domainName?: string, isNew = false) {
    if (!this.email || !domainName) return '';

    const emailDomain = this.email.split('@')[1];
    if (!emailDomain || domainName.toLowerCase() === emailDomain.toLowerCase()) return '';

    return domainNameWarning(domainName, isNew ? undefined : userFullname(this));
  }

  override get childPropertyNames(): Array<keyof UserPrincipal> {
    return UserPrincipal.propNames('userRoles', 'organisations');
  }

  override getPropertyPathsFor(purpose: GraphLoadPurpose): Array<keyof UserPrincipal> {
    if (purpose === GraphLoadPurpose.View) {
      return [UserPrincipal.propName('userRoles')];
    } else if (purpose === GraphLoadPurpose.Delete) {
      return [UserPrincipal.propName('organisations')];
    } else {
      return this.childPropertyNames;
    }
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
