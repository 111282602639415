import { IdentityPrincipal } from './identity-principal';
import { Role } from './role';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { BaseEntity } from '../base-entity';
/// </code-import>

export interface OrganisationPrincipal {
  defaultRoleId?: string;
  domainName?: string;
  defaultRole: Role;
}

/// <module-code> Place module level code between <module-code> tags
export enum OrganisationPrincipalCustomAction {
  Impersonate = 'Impersonate'
}

export type OrganisationName = Pick<OrganisationPrincipal, 'name'>;

const typeFuncs = BaseEntity.makeStaticTypeFunctions<OrganisationPrincipal>(EntityName.Organisation);
/// </module-code>

export class OrganisationPrincipal extends IdentityPrincipal {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;
  static propNames = typeFuncs.propNames;
  static qualifiedPropName = typeFuncs.qualifiedPropName;
  static qualifiedPropNames = typeFuncs.qualifiedPropNames;

  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.Organisation),
    [OrganisationPrincipalCustomAction.Impersonate]: {
      action: OrganisationPrincipalCustomAction.Impersonate,
      resource: EntityName.Organisation
    },
    [CrudAction.Update]: AuthzContext.updateFor(...OrganisationPrincipal.qualifiedPropNames('name', 'domainName')),
    [CrudAction.Delete]: AuthzContext.updateFor(OrganisationPrincipal.qualifiedPropName('isDeleted'))
  };
  /// </code>
  // Generated code. Do not place code below this line.
}
