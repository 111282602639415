import { BaseEntity } from '../base-entity';
import { IdentityPrincipal } from './identity-principal';
import { GroupPrincipal } from './group-principal';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { UserPrincipal } from './user-principal';
/// </code-import>

export interface GroupMembership {
  memberId: string;
  groupId: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  group: GroupPrincipal;
  member: IdentityPrincipal;
}

/// <module-code> Place module level code between <module-code> tags
const typeFuncs = BaseEntity.makeStaticTypeFunctions<GroupMembership>(EntityName.GroupMembership);
/// </module-code>

export class GroupMembership extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName = typeFuncs.propName;

  static authorization: AuthzContextsActionMap = {
    ...AuthzContextsActionMap.crudFor(EntityName.GroupMembership),
    [CrudAction.Update]: [
      AuthzContext.createFor(EntityName.GroupMembership),
      AuthzContext.deleteFor(EntityName.GroupMembership)
    ]
  };

  get user(): UserPrincipal | undefined {
    return this.member instanceof UserPrincipal ? (this.member as unknown as UserPrincipal) : undefined;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
